import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, SvgIcon } from "@mui/material";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import UserSquare from "@untitled-ui/icons-react/build/esm/UserSquare";
import Calendar from "@untitled-ui/icons-react/build/esm/Calendar";
import MarkerPin04 from "@untitled-ui/icons-react/build/esm/MarkerPin04";
import ReceiptCheck from "@untitled-ui/icons-react/build/esm/ReceiptCheck";
import CurrencyDollarCircle from "@untitled-ui/icons-react/build/esm/CurrencyDollarCircle";
import Settings02 from "@untitled-ui/icons-react/build/esm/Settings02";
import Tool02 from "@untitled-ui/icons-react/build/esm/Tool02";
import PaperClip from "@untitled-ui/icons-react/build/esm/Paperclip";

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.schedule),
            path: paths.dashboard.schedule.index,
            icon: (
              <SvgIcon fontSize="small">
                <Calendar />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.map),
            path: paths.dashboard.map.index,
            icon: (
              <SvgIcon fontSize="small">
                <MarkerPin04 />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.jobs),
            path: paths.dashboard.jobs.index,
            icon: (
              <SvgIcon fontSize="small">
                <Tool02 />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.invoices),
            path: paths.dashboard.invoices.index,
            icon: (
              <SvgIcon fontSize="small">
                <CurrencyDollarCircle />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.quotes),
            path: paths.dashboard.quotes.index,
            icon: (
              <SvgIcon fontSize="small">
                <PaperClip />
              </SvgIcon>
            ),
            label: <Chip color="primary" label="New" size="small" />,
          },
          {
            title: t(tokens.nav.staff),
            path: paths.dashboard.staff.index,
            icon: (
              <SvgIcon fontSize="small">
                <UserSquare />
              </SvgIcon>
            ),
          },
          // {
          //   title: t(tokens.nav.overview),
          //   path: paths.dashboard.index,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <HomeSmileIcon />
          //     </SvgIcon>
          //   ),
          // },
          {
            title: t(tokens.nav.analytics),
            path: paths.dashboard.analytics,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.settings),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <Settings02 />
              </SvgIcon>
            ),
          },
        ],
      },
      // {
      //   subheader: t(tokens.nav.customers),
      //   items: [
      //     {
      //       title: t(tokens.nav.customers),
      //       path: paths.dashboard.customers.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Users03Icon />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.quotes),
      //       path: paths.dashboard.quotes.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CurrencyDollarCircle />
      //         </SvgIcon>
      //       ),
      //       label: <Chip color="primary" label="New" size="small" />,
      //     },
      //     {
      //       title: t(tokens.nav.invoices),
      //       path: paths.dashboard.invoices.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ReceiptCheck />
      //         </SvgIcon>
      //       ),
      //     },
      //   ],
      // },
      // {
      //   subheader: t(tokens.nav.jobs),
      //   items: [
      //     {
      //       title: t(tokens.nav.schedule),
      //       path: paths.dashboard.schedule.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Calendar />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.map),
      //       path: paths.dashboard.map.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <MarkerPin04 />
      //         </SvgIcon>
      //       ),
      //     },
      //   ],
      // },
      // {
      //   subheader: t(tokens.nav.manage),
      //   items: [
      //     {
      //       title: t(tokens.nav.staff),
      //       path: paths.dashboard.staff.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <UserSquare />
      //         </SvgIcon>
      //       ),
      //     },
      //     {
      //       title: t(tokens.nav.settings),
      //       path: paths.dashboard.account,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Settings02 />
      //         </SvgIcon>
      //       ),
      //     },
      //   ],
      // },
    ];
  }, [t]);
};
