import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./app";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <Auth0Provider
          domain="wrkwek.us.auth0.com"
          clientId="I61nL61dZnQQXXbf5DRDZ0GFEJVU8Xrb"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://api.wrkwek.com",
            scope: "openid profile email read:customers",
          }}
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
          cacheLocation="localstorage"
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
